<template>
  <div class="clients-page">
    <div class="block-banner clients-banner">
      <div class="bg--black">
        <div class="container">
          <h2 class="block-banner__title"  v-scroll-reveal="{
            distance: '80px',
            origin: 'left'
          }">Clientes</h2>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="clients">
        <div class="clients__container">
          <div class="clients__wrapper row">
            <div class="clients__item col-xs-12 col-sm-6 col-md-4" v-for="item in 14" :key="item">
              <div class="clients__inner" v-scroll-reveal="{ delay: 400 }">
                <div class="clients__img">
                  <div class="d-vertical">
                    <img class="clients__info--img" :src="require(`@/assets/logos/logo${item}.jpg`)" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "clientsPage",
  data() {
    return {
      listNews: [],
    };
  },
  created() {
    this.fetchNews();
  },
  methods: {
    fetchNews() {
      // eslint-disable-next-line no-console
      console.log('news');
    }
  }
}
</script>